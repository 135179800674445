"use client";

import { checkout } from "@/app/actions";
import { GraduationCap } from "lucide-react";
import mixpanel from "mixpanel-browser";
import { FC, useState } from "react";
import Button from "../base/Button";

type Props = {
  showSubtext: boolean;
  buttonText: string;
};

const UpgradeButton: FC<Props> = ({ showSubtext, buttonText }) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className="relative w-full">
      <Button
        icon={<GraduationCap strokeWidth={2.4} className="w-4 h-4 shrink-0" />}
        size="md"
        text={buttonText}
        variant="primary"
        onClick={() => {
          setLoading(true);
          checkout();
          mixpanel.track("Navigated to stripe checkout");
        }}
        loading={loading}
        full
      />
      {showSubtext && (
        <div className="absolute -bottom-5 text-[10px] text-center w-full text-neutral-5">
          You are free to cancel at any time.
        </div>
      )}
    </div>
  );
};

export default UpgradeButton;

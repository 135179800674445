import { Database } from "@/types/supabase";
import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
import { Session } from "@supabase/supabase-js";
import { useRouter } from "next/navigation";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useState,
  PropsWithChildren,
  useMemo,
} from "react";
import mixpanel from "mixpanel-browser";
import { createOrGetCustomerId } from "@/app/actions";

type SessionContextType = {
  session: Session | null;
  setSession: Dispatch<SetStateAction<Session | null>>;
  profile: Database["public"]["Tables"]["profiles"]["Row"] | null;
  setProfile: Dispatch<
    SetStateAction<Database["public"]["Tables"]["profiles"]["Row"] | null>
  >;
  isFetchingSession: boolean;
};

export const SessionContext = createContext<SessionContextType | null>(null);

const SessionContextProvider = ({ children }: PropsWithChildren) => {
  const [supabase] = useState(() => createClientComponentClient<Database>());
  const [session, setSession] = useState<Session | null>(null);
  const [profile, setProfile] = useState<
    Database["public"]["Tables"]["profiles"]["Row"] | null
  >(null);
  const [isFetchingSession, setIsFetchingSession] = useState(true);
  const router = useRouter();
  const searchParams = useMemo(() => {
    return new URLSearchParams(
      typeof window !== "undefined" ? window.location.search : ""
    );
  }, []);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setIsFetchingSession(false);
    });

    supabase.auth.onAuthStateChange(async (_event, session) => {
      setSession(session);

      const code = searchParams.get("code");
      if (code) {
        router.replace("/");
      }
    });
  }, [supabase, router, searchParams]);

  useEffect(() => {
    async function fetchProfile() {
      if (session) {
        const { data } = await supabase.from("profiles").select("*").single();
        setProfile(data);
        if (data && !data.stripe_customer_id) {
          mixpanel.track("Sign up success");

          await Promise.all([
            fetch("/api/addContact", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                email: data.email ?? "",
                firstName: data.full_name ?? "",
                lastName: "",
              }),
            }),
            createOrGetCustomerId(data.id),
          ]);
        }
      }
    }
    fetchProfile();
  }, [session, supabase]);

  return (
    <SessionContext.Provider
      value={{
        session,
        setSession,
        profile,
        setProfile,
        isFetchingSession,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export default SessionContextProvider;

import { ChatUsage } from "@/context/UsageContext";
import { Database } from "@/types/supabase";
import * as Sentry from "@sentry/nextjs";
import { SupabaseClient } from "@supabase/supabase-js";
import { toast } from "react-toastify";
import { CHAT_QUOTA, MAX_MESSAGES_IN_CHAT, BLESSED_USERS } from "./consts";
import { SubjectKey, TopicKey } from "./subjects/subjects";
import { Tables } from "@/types/supabase";

export type Message = {
  isUser: boolean;
  messageContent: string;
  photoUUID?: string;
  photoData?: string;
};

export const transformMessage = (
  message: Message
): { role: "assistant" | "user"; content: any[] } => {
  const content: any[] = [{ type: "text", text: message.messageContent }];
  if (message.photoData) {
    content.push({
      type: "image_url",
      image_url: {
        url: message.photoData,
      },
    });
  }
  return {
    role: message.isUser ? "user" : "assistant",
    content,
  };
};

export const getGPTResponse = async ({
  messages,
  topicKey,
  subjectKey,
}: {
  messages: Message[];
  topicKey: TopicKey;
  subjectKey: SubjectKey;
}): Promise<ReadableStream<Uint8Array>> => {
  let controlledMessages: Message[] = [];
  if (messages.length >= MAX_MESSAGES_IN_CHAT) {
    controlledMessages.push(...messages.slice(0, 2), ...messages.slice(-5));
  } else {
    controlledMessages = messages;
  }
  const chatData = await fetch("/api/chat", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      messages: controlledMessages.map((msg) => transformMessage(msg)),
      topicKey,
      subjectKey,
    }),
  });

  const { ok, body } = chatData;
  if (!ok || !body) {
    Sentry.captureException(
      "Error when getting GPT response: " + chatData.statusText
    );
    throw new Error(chatData.statusText);
  }
  return body;
};

export const getGPTResponseForSummary = async (
  messages: Message[]
): Promise<ReadableStream<Uint8Array>> => {
  let controlledMessages: Message[] = [];
  if (messages.length >= MAX_MESSAGES_IN_CHAT) {
    controlledMessages.push(...messages.slice(0, 2), ...messages.slice(-5));
  } else {
    controlledMessages.push(...messages);
  }

  const chatData = await fetch("/api/summaryChat", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      messages: controlledMessages.map((msg) => transformMessage(msg)),
    }),
  });

  const { ok, body } = chatData;
  if (!ok || !body) {
    Sentry.captureException(
      "Error when getting GPT response for summary: " + chatData.statusText
    );
    throw new Error(chatData.statusText);
  }
  return body;
};

export const generateSimilarQuestion = async (question: {
  type: "text" | "image";
  value: string;
}): Promise<string> => {
  const transformedQuestion =
    question.type === "text"
      ? { type: "text", text: question.value }
      : { type: "image_url", image_url: { url: question.value } };

  const generation = await fetch("/api/generate", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      messages: [
        {
          role: "user",
          content: [transformedQuestion],
        },
      ],
    }),
  });

  const { ok, body } = generation;
  if (!ok || !body) {
    Sentry.captureException(
      "Error when getting generation: " + generation.statusText
    );
    throw new Error(generation.statusText);
  }

  const { questionContent } = await generation.json();

  return questionContent;
};

export const getPhotoURL = (photoUUID: string) => {
  return `${process.env.NEXT_PUBLIC_SUPABASE_URL}/storage/v1/object/public/photos/${photoUUID}`;
};

export const getReferralLink = (referralID: string) => {
  return `https://app.stellaai.com.au/?referralCode=${referralID}`;
};

export const copyTextToClipboard = async (
  text: string,
  successMessage?: string
) => {
  try {
    if (navigator?.clipboard?.writeText) {
      await navigator.clipboard.writeText(text);
      toast.success(successMessage ?? "Copied to clipboard!");
    }
  } catch (err) {
    Sentry.captureException("Error when copying to clipboard: " + err);
    console.error(err);
  }
};

export const getChatUsage = async ({
  supabase,
}: {
  supabase: SupabaseClient<Database, "public", any>;
}): Promise<ChatUsage> => {
  const { data: profile, error } = await supabase
    .from("profiles")
    .select("*")
    .single();
  if (!profile || error) {
    Sentry.captureException(
      "Error when getting chat usage. Profile could not be found : " + error
    );
    throw Error;
  }

  const [chatsResponse, referralsResponse] = await Promise.all([
    supabase
      .from("chats")
      .select("*", { count: "exact", head: true })
      .eq("user_id", profile.id),
    supabase
      .from("referrals")
      .select("*", { count: "exact", head: true })
      .eq("is_valid", true),
  ]);

  const used = chatsResponse.count ?? 0;
  const recentReferralsCount = referralsResponse.count ?? 0;

  const base = hasFullAccess(profile ?? undefined)
    ? CHAT_QUOTA.PRO
    : CHAT_QUOTA.FREE;

  const available = base + 10 * recentReferralsCount;

  return {
    used,
    available,
  };
};

export const hasFullAccess = (profile?: Tables<"profiles">) => {
  if (!profile) return false;
  if (profile.stripe_subscription_status === "active") return true;

  return profile.email && BLESSED_USERS.includes(profile.email);
};

import classNames from "classnames";
import { ButtonHTMLAttributes, FC, ReactNode } from "react";
import Loader from "./Loader";

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  icon?: ReactNode;
  text: string;
  variant: "text" | "secondary" | "white" | "primary";
  size: "sm" | "md" | "lg";
  loading?: boolean;
  full?: boolean;
};

const Button: FC<Props> = ({
  onClick,
  icon,
  text,
  disabled = false,
  variant,
  size,
  loading,
  full,
  ...props
}) => {
  const baseButtonStyle = classNames(
    "flex items-center justify-center px-4 py-1 disabled:bg-gray-300 disabled:shadow-none text-center",
    full ? "w-full" : "w-auto"
  );

  const getVariantStyle = () => {
    switch (variant) {
      case "primary":
        return "bg-primary-6 hover:bg-primary-5 rounded-full shadow-md hover:shadow-sm [&>*]:text-white text-white";
      case "secondary":
        return "outline outline-1 bg-white/5 hover:bg-white/10 rounded-full [&>*]:text-white text-white";
      case "white":
        return "[&>*]:text-primary-6 text-primary-6 shadow-md rounded-full bg-white";
      case "text":
        return "hover:bg-white/10 rounded-md underline underline-offset-4 decoration-white/80 decoration-2 text-primary-10";
    }
  };

  const getButtonPadding = () => {
    switch (size) {
      case "sm":
        return "px-2 py-1 gap-1";
      case "md":
        return "px-6 py-2 gap-2";
      case "lg":
        return "px-6 py-2.5 gap-3";
    }
  };

  const getTextSize = () => {
    switch (size) {
      case "sm":
        return "text-xs";
      case "md":
        return "text-sm";
      case "lg":
        return "text-base";
    }
  };

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        baseButtonStyle,
        getVariantStyle(),
        getButtonPadding()
      )}
      {...props}
    >
      {loading ? <Loader color="text-white" /> : icon}
      <span
        className={classNames(getTextSize(), "whitespace-nowrap font-semibold")}
      >
        {text}
      </span>
    </button>
  );
};

export default Button;

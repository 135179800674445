import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { DialogDescription } from "@radix-ui/react-dialog";
import Image from "next/image";
import { FC } from "react";
import UpgradeButton from "../plans/UpgradeButton";

type Props = {
  show: boolean;
  onOpenChange: (open: boolean) => void;
  paywalled?: boolean;
};

const UpgradeModal: FC<Props> = ({ show, onOpenChange, paywalled = false }) => {
  return (
    <Dialog open={show} onOpenChange={onOpenChange}>
      <DialogContent className="flex p-0 max-w-2xl">
        <div className="hidden md:block rounded-l-lg bg-slate-100 p-12">
          <Image
            alt="preview"
            src="/potato.svg"
            className="object-cover"
            width={240}
            height={300}
          />
        </div>
        <DialogHeader className="p-6">
          <DialogTitle className="pb-3 text-xl">
            {paywalled
              ? "Start Your Plan To Continue"
              : "Start Your Plan Today"}
          </DialogTitle>
          <DialogDescription className="text-sm text-slate-600 h-full flex justify-around flex-col">
            <div className="mx-5 my-3 p-3 border border-slate-200 flex items-center justify-between rounded-md">
              <span className="font-semibold text-lg text-slate-700">
                Stella AI
              </span>
              <div className="flex flex-col">
                <span className="tracking-wide text-base">$14.99/week</span>
                <span className="text-slate-500 tracking-wide text-[10px] text-right -mt-1">
                  Billed monthly.
                </span>
              </div>
            </div>

            <div className="w-full p-3">
              <UpgradeButton buttonText="Continue" showSubtext />
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default UpgradeModal;

export const CHAT_QUOTA = {
  PRO: 300,
  FREE: 0,
};
export const MAX_MESSAGES_IN_CHAT = 10;

export const ALL_HSC_SUBJECTS = [
  "Aboriginal Studies",
  "Agriculture",
  "Ancient History",
  "Arabic",
  "Arabic Continuers",
  "Arabic Extension",
  "Armenian Continuers",
  "Biology",
  "Business Studies",
  "Chemistry",
  "Chinese",
  "Chinese Beginners",
  "Chinese Continuers",
  "Chinese Extension",
  "Chinese and Literature",
  "Chinese in Context",
  "Community and Family Studies",
  "Croatian Continuers",
  "Dance",
  "Design and Technology",
  "Drama",
  "Dutch Continuers",
  "Earth and Environmental Science",
  "Economics",
  "Engineering Studies",
  "English Advanced",
  "English Standard",
  "English Extension",
  "English EAL/D",
  "English Life Skills",
  "English Studies",
  "Filipino Continuers",
  "Food Technology",
  "French",
  "French Beginners",
  "French Continuers",
  "French Extension",
  "Geography",
  "German",
  "German Beginners",
  "German Continuers",
  "German Extension",
  "Greek",
  "Classical Greek Continuers",
  "Classical Greek Extension",
  "Modern Greek Beginners",
  "Modern Greek Continuers",
  "Modern Greek Extension",
  "Hebrew",
  "Classical Hebrew Continuers",
  "Classical Hebrew Extension",
  "Modern Hebrew Continuers",
  "Hindi Continuers",
  "History Extension",
  "Hungarian Continuers",
  "Indonesian",
  "Indonesian Beginners",
  "Indonesian Continuers",
  "Indonesian Extension",
  "Indonesian and Literature",
  "Industrial Technology",
  "Information Processes and Technology",
  "Investigating Science",
  "Italian",
  "Italian Beginners",
  "Italian Continuers",
  "Italian Extension",
  "Japanese",
  "Japanese Beginners",
  "Japanese Continuers",
  "Japanese Extension",
  "Japanese in Context",
  "Khmer Continuers",
  "Korean",
  "Korean Beginners",
  "Korean Continuers",
  "Korean and Literature",
  "Korean in Context",
  "Latin",
  "Latin Continuers",
  "Latin Extension",
  "Legal Studies",
  "Macedonian Continuers",
  "Mathematics Advanced",
  "Mathematics Standard",
  "Mathematics Extension 1",
  "Mathematics Extension 2",
  "Mathematics Life Skills",
  "Modern History",
  "Music 1",
  "Music 2",
  "Music Extension",
  "Persian Continuers",
  "Personal Development, Health and Physical Education",
  "Physics",
  "Polish Continuers",
  "Portuguese Continuers",
  "Punjabi Continuers",
  "Russian Continuers",
  "Serbian Continuers",
  "Science Extension",
  "Society and Culture",
  "Software Design and Development",
  "Spanish",
  "Spanish Beginners",
  "Spanish Continuers",
  "Spanish Extension",
  "Studies of Religion I",
  "Studies of Religion II",
  "Swedish Continuers",
  "Tamil Continuers",
  "Textiles and Design",
  "Turkish Continuers",
  "Vietnamese Continuers",
  "Visual Arts",
];

export const BLESSED_USERS = [
  "rebeccaannmoore273@gmail.com",
  "sabu04@hotmail.com",
  "claudia.gasparini@alumni.griffithuni.edu.au",
  "astroant@hotmail.com",
  "ray@medentry.edu.au",
  "alisawu.syd@gmail.com",
];
